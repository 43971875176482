import React from 'react';
import * as z from 'zod';
import { Form, FormDrawer, InputField } from 'components/form';
import { Button } from 'components/elements';
import { useCreatePortfolio } from '../api/createPortfolio';
import { useNavigate } from 'react-router-dom';

const schema = z.object({
  name: z.string().min(1, 'Required'),
  ticker_type: z.enum(['stock', 'option'], {
    required_error: "Please select a ticker type",
  }),
});

export const CreatePortfolio = ({ title }) => {
  const createPortfolioMutation = useCreatePortfolio();
  const navigate = useNavigate();

  return (
    <>
      <FormDrawer
        size="sm"
        isDone={createPortfolioMutation.isSuccess}
        triggerButton={
          <Button className="w-full py-5">
            <span>{title}</span>
          </Button>
        }
        title="Create New Portfolio"
        submitButton={
          <Button
            form="create-portfolio"
            type="submit"
            size="sm"
            isLoading={createPortfolioMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="create-portfolio"
          onSubmit={async values => {
            await createPortfolioMutation.mutateAsync({ data: values });
            navigate('/app/portfolios/create/success');
          }}
          schema={schema}
          defaultValues={{
            ticker_type: 'stock' // Set default value for ticker_type
          }}
        >
          {({ register, formState, setValue, watch }) => (
            <>
              <InputField
                label="Portfolio Name"
                error={formState.errors['name']}
                registration={register('name')}
              />
              
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  Ticker Type
                </label>
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className={`px-4 py-2 rounded-md flex-1 transition-colors ${
                      watch('ticker_type') === 'stock'
                        ? 'bg-flamingo-500 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-dim-300 dark:text-gray-200 dark:hover:bg-dim-200'
                    }`}
                    onClick={() => setValue('ticker_type', 'stock', { 
                      shouldValidate: true,
                      shouldDirty: true 
                    })}
                  >
                    Stocks
                  </button>
                  <button
                    type="button"
                    className={`px-4 py-2 rounded-md flex-1 transition-colors ${
                      watch('ticker_type') === 'option'
                        ? 'bg-flamingo-500 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-dim-300 dark:text-gray-200 dark:hover:bg-dim-200'
                    }`}
                    onClick={() => setValue('ticker_type', 'option', { 
                      shouldValidate: true,
                      shouldDirty: true 
                    })}
                  >
                    Options
                  </button>
                </div>
                {formState.errors['ticker_type'] && (
                  <div className="mt-1 text-sm text-red-500">
                    {formState.errors['ticker_type'].message}
                  </div>
                )}
              </div>
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};
