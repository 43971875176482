import React from 'react';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { useColorModeContext } from 'context/ColorContext';

export const MainLayout = ({ children, headerHeight, headerContent }) => {
  const darkMode = useColorModeContext();

  return (
    <div className={darkMode[0] ? `dark` : ``}>
      <div className="relative">
        {/* Background illustration */}
        <div className="flex w-full z-0 h-[36rem] md:h-[42rem] lg:h-[46rem]">
          <div className="h-full flex flex-col justify-between w-3/4">
            <div
              className="rounded-b-full h-[2rem] w-[4rem] lg:h-[6rem] lg:w-[12rem] 
            bg-[#FE5F00] opacity-[.25] mx-auto"
            ></div>
            <div
              className="rounded-full h-[10rem] w-[10rem] lg:h-[20rem] lg:w-[20rem] 
            bg-[#FE5F00] opacity-[.25] -ml-24"
            ></div>
          </div>
          <div className="w-1/4 h-full items-center">
            <div
              className="absolute overflow-hidden rounded-l-full h-[24rem]
					 lg:h-[32rem] w-[16rem] bg-[#FE5F00] opacity-[.25] right-0"
            ></div>
          </div>
        </div>
        <div className="z-1 absolute top-0 w-full">
          <Header height={headerHeight} children={headerContent} />
          <div className="dark:bg-dim-300 dark:text-white">{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
