import React from 'react';
import { AuthNavBar } from './AuthNavBar';
import abstract from 'assets/abstract.png';
import { Link } from 'react-router-dom';
import { useColorModeContext } from 'context/ColorContext';

export const AuthLayout = ({ children, option }) => {
  const darkMode = useColorModeContext();

  return (
    <div className={`${darkMode[0] ? `dark` : ``} relative h-full`}>
      <AuthNavBar option={option} />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 dark:bg-dim-300 dark:text-white">
        <div
          className="hidden md:flex md:order-2 lg:order-2 bg-[#E3F0FF] flex items-center 
        justify-center py-36"
        >
          <img src={abstract} className="md:w-1/6 lg:w-1/3" alt="abstract" />
        </div>
        <div className="md:order-1 lg:order-2 pt-28 mx-8 md:mx-auto flex flex-col justify-between">
          <div className="min-h-screen">{children}</div>
          <div className="flex flex-col items-center text-gray-500 py-12">
            <h2 className="text-lg md:text-xl font-bold">
              All rights reserved. Tickerzone 2024
            </h2>
            <p>
              <Link to="/privacy-policy">Privacy Policy</Link> .{' '}
              <Link to="/terms-of-use">Terms of Use</Link> .{' '}
              <Link to="/license">License</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
