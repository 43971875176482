import React from 'react';
import { Button, ConfirmationDialog } from 'components/elements';
import PropTypes from 'prop-types';

import { useDeleteActiveTrade } from '../api/deleteActiveTrade';
import { useColorModeContext } from 'context/ColorContext';

import './../assets/frameclick.css';

export const DeleteActiveTrade = ({ tradeId, ticker }) => {
  const deleteActiveTradeMutation = useDeleteActiveTrade();
  const darkMode = useColorModeContext();
  const colorScheme = darkMode[0] ? 'dark' : 'light';

  return (
    <>
      <ConfirmationDialog
        icon="danger"
        title="Delete Active Trade"
        body={`Are you sure you want to remove ${ticker} from holdings?`}
        triggerButton={
          <button className={`trigger-button flex items-center justify-center m-2 mx-4 cursor-pointer ${colorScheme}`}>
            <span>Remove</span>
          </button>
        }
        confirmButton={
          <Button
            isLoading={deleteActiveTradeMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () =>
              await deleteActiveTradeMutation.mutateAsync({ tradeId })
            }
          >
            Delete Trade
          </Button>
        }
      />
    </>
  );
};

DeleteActiveTrade.propTypes = {
  tradeId: PropTypes.string.isRequired,
};
