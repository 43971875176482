import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/logo-dark.svg';
import darklogo from 'assets/logo.png';
import { useAuth } from 'lib/auth';
import { useColorModeContext } from 'context/ColorContext';

export const NotFound = () => {
  const { user } = useAuth();
  const darkMode = useColorModeContext();
  return (
    <div className={` ${darkMode[0] ? `dark` : ``}`}>
      <div className="dark:bg-black dark:text-white min-h-screen">
        <div className="flex justify-between items-center py-8 mx-8">
          <Link to={user ? '/app' : '/'}>
            <img
              src={darkMode[0] ? darklogo : logo}
              alt="logo"
              className="h-8 w-auto"
            />
          </Link>
        </div>
        <div className="my-48 w-full flex flex-col justify-center items-center space-y-4">
          <p className="font-bold text-3xl">404</p>
          <p className="font-bold text-xl">Page doesn't exist</p>
          <Link
            to={user ? '/app' : '/'}
            className="bg-flamingo-500 px-4 py-2 text-white rounded-md hover:bg-flamingo-600"
          >
            Go Back Home
          </Link>
        </div>
      </div>
    </div>
  );
};
