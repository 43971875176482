import React from 'react';
import logo from 'assets/logo-dark.svg';
import { Link } from 'react-router-dom';
import { useAuth } from 'lib/auth';

export const AuthNavBar = ({ option }) => {
  const { user } = useAuth();

  function getInitials(firstName, lastName) {
    const initials = `${firstName?.charAt(0) || ''}${
      lastName?.charAt(0) || ''
    }`;
    return initials.toUpperCase();
  }

  return (
    <div className="absolute top-0 left-0 uppercase w-full my-4 dark:text-white">
      <div className="flex justify-between items-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-2 pb-8">
        <Link to="/">
          <img src={logo} alt="logo" className="h-8 w-auto" />
        </Link>
        <div className="space-x-4 flex items-center">
          <Link to="/about" className="hidden md:block px-4 py-2 font-semibold">
            About
          </Link>
          {user ? (
            <>
              <div
                className="bg-black font-[1.5rem] text-white h-8 w-8 md:h-12 md:w-12 bg-cover 
            rounded-full flex items-center justify-center dark:border dark:border-white"
              >
                {getInitials(user.first_name, user.last_name)}
              </div>
            </>
          ) : (
            <>
              {option === 'login' ? (
                <Link
                  to="/auth/create-new-account"
                  className="px-4 py-2 bg-flamingo-500 text-gray-100 rounded"
                >
                  SIGN UP
                </Link>
              ) : option === 'create-new-account' ? (
                <Link
                  to="/auth/login"
                  className="px-4 py-2 bg-flamingo-500 text-gray-100 rounded"
                >
                  LOGIN
                </Link>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
