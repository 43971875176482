import React from 'react';
import { Link } from 'react-router-dom';
import { AuthLayout } from '../components/AuthLayout';

export const ResetPasswordSuccess = () => {
  return (
    <AuthLayout option="reset-password-success">
      <div className="text-center my-32">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">
          Success! 
        </h1>
        <p className="text-lg md:text-xl mb-8">
          Your password has been reset. Proceed to login.
        </p>
      </div>
        <Link
            to="/auth/login"
            className="rounded-full w-fit outline-none focus:outline-none px-8 py-1.5"
        >
            Back to login
        </Link>
    </AuthLayout>
  );
};