import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';
import { getPortfolios } from './getPortfolios';
import storage from 'utils/storage';

export const createPortfolio = ({ data }) => {
  return axios.post(`/tapp/portfolio/add`, data);
};

export const useCreatePortfolio = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async newPortfolio => {
      await queryClient.cancelQueries('portfolios');

      const previousPortfolios = queryClient.getQueryData('portfolios');

      queryClient.setQueryData('portfolios', [
        ...(previousPortfolios || []),
        newPortfolio.data,
      ]);

      return { previousPortfolios };
    },
    onError: (_, __, context) => {
      if (context?.previousPortfolios) {
        queryClient.setQueryData('portfolios', context.previousPortfolios);
      }
    },
    onSuccess: async data => {
      queryClient.invalidateQueries('portfolios');
      const portfolios = await getPortfolios();
      storage.setActivePortfolio(portfolios[0]?.portfolio_id);
      addNotification({
        type: 'success',
        title: data.detail,
      });
    },
    ...config,
    mutationFn: createPortfolio,
  });
};
