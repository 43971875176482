import React from 'react';
import { AuthLayout } from '../components/AuthLayout';

export const ActivateAccount = () => {
  return (
    <AuthLayout option="create-new-account">
      <div className="text-center my-32">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">
          Thank you for signing up!
        </h1>
        <p className="text-lg md:text-xl mb-8">
          Please check your email to activate your account.
        </p>
      </div>
    </AuthLayout>
  );
};
