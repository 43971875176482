import React from 'react';
import { Link } from 'react-router-dom';
import { Heading } from 'components/layout/components/Heading';
import abstract from 'assets/abstract.png';

export const Solutions = () => {
  return (
    <div className="grid grid-cols-12 gap-6 bg-indigo-500 dark:bg-dim-300">
      <div className="hidden md:flex md:col-span-4 w-full">
        <img src={abstract} alt="abstract" className="w-auto" />
      </div>
      <div
        className="col-span-12 md:col-span-8 flex flex-col justify-center items-center
			  w-5/6 mx-auto pt-8 pb-16"
      >
        <Heading color="white">
          Solutions to empower every investor
        </Heading>
        <div className="my-8 space-y-8 w-full md:w-4/6 mx-auto">
          <div className="flex space-x-4 text-white items-start">
            <div className="flex items-center">
              <div className="px-4 py-2 bg-gray-600 rounded h-fit">
                <p className="text-white text-xl font-bold">1</p>
              </div>
              <div className="w-12 h-1 bg-gray-600"></div>
            </div>
            <div className="space-y-2">
              <h4 className="my-0">Invest with confidence</h4>
              <p>
              Target setting helps you refine your investment thesis and allows you to evaluate positions with data-driven insights.
              </p>
            </div>
          </div>
          <div className="flex space-x-4 text-white items-start">
            <div className="flex items-center">
              <div className="px-4 py-2 bg-gray-600 rounded h-fit">
                <p className="text-white text-xl font-bold">2</p>
              </div>
              <div className="w-12 h-1 bg-gray-600"></div>
            </div>
            <div className="space-y-2">
              <h4 className="my-0">Have a clear view of your portfolio at your fingertips</h4>
              <p>
              Tickerzone provides a simple overview of all your investments in a single dashboard. 
              </p>
            </div>
          </div>
          <div className="flex space-x-4 text-white items-start">
            <div className="flex items-center">
              <div className="px-4 py-2 bg-gray-600 rounded h-fit">
                <p className="text-white text-xl font-bold">3</p>
              </div>
              <div className="w-12 h-1 bg-gray-600"></div>
            </div>
            <div className="space-y-2">
              <h4 className="my-0">Affordable insights and transparent pricing</h4>
              <p>
              Our affordable and intuitive platform puts actionable insights at your fingertips, so you can make informed decisions without breaking the bank.
              </p>
            </div>
          </div>
          <div>
        <Link
          to="/auth/create-new-account/"
          className="text-white uppercase bg-flamingo-500 px-3 py-2 rounded-md text-xs font-medium"
        >
          Get Started
        </Link>
      </div>
        </div>
      </div>
      
    </div>
  );
};
