import { axios } from 'lib/axios';
import { useState, useCallback } from 'react';


export const useFetchLinkToken = () => {
    const [linkToken, setLinkToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchLinkToken = useCallback(async () => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.get('/tapp/account/get_link_token');
            if (response.data) {
                setLinkToken(response.data.link_token); // Assuming link_token is correctly nested
            } else {
                throw new Error('No token received');
            }
        } catch (e) {
            setError(e.message);
            setLinkToken('');
        } finally {
            setIsLoading(false);
        }
    }, []); // Dependencies array is empty, ensuring this function is memoized

    return { linkToken, fetchLinkToken, isLoading, error };
};
