// ColorModeContext.js
import { useDarkMode } from 'hooks/useDarkMode';
import React, { createContext, useContext, useEffect } from 'react';

const ColorModeContext = createContext();

export const useColorModeContext = () => useContext(ColorModeContext);

export const ColorModeProvider = ({ children }) => {
  const colorModeHook = useDarkMode();

  useEffect(() => {
    if (colorModeHook.isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [colorModeHook.isDarkMode]);

  return (
    <ColorModeContext.Provider value={colorModeHook}>
      {children}
    </ColorModeContext.Provider>
  );
};
