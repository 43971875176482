import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const Heading = ({ children, color }) => {
  return (
    <div className="w-fit space-y-2 py-8">
      <h2
        className={classNames(
          color === 'white' ? 'text-white' : 'text-black',
          `text-2xl lg:text-4xl font-bold dark:text-white`
        )}
      >
        {children.slice(0, -6)}
        <span
          className={classNames(
            color === 'white' ? 'decoration-white' : 'decoration-[#FE5F00]',
            'underline decoration-4 underline-offset-[15px]'
          )}
        >
          {children.slice(-6)}
        </span>
      </h2>
    </div>
  );
};
