import React from 'react';
import QRCodeImage from 'assets/code.png';
import { ColorSwitch } from './ColorSwitch';

export const SidebarFooter = () => {
  return (
    <div>
      <div className="flex flex-col space-y-2 justify-center">
        {/* <div className="mx-auto">
          <img src={QRCodeImage} alt="QR Code" className="w-24" />
          <div className="text-xs text-center mt-2">Scan to Subscribe</div>
        </div> */}
        {/* <a
          target="_blank"
          rel="noreferrer"
          className="bg-gradient-to-r from-flamingo-500 to-indigo-500 text-white py-1 px-3 rounded-lg shadow-md 
             transition duration-300 ease-in-out transform hover:scale-105 flex flex-col items-center space-x-2"
          href="https://buy.stripe.com/3cs4izeac2Zq5Co4gh"
        >
          <span className="font-semibold text-sm">Subscribe</span>

          <span className="text-gray-100 text-xs whitespace-nowrap">
            (Recommended: $5 - $14)
          </span>
        </a> */}
        <ColorSwitch />
      </div>
    </div>
  );
};
