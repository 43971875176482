import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';

export const addPosition = ({ data }) => {
  return axios.post(`/tapp/trades/enter_position`, data);
};

addPosition.propTypes = {
  data: PropTypes.shape({
    ticker: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    entry_price: PropTypes.number.isRequired,
    price_target: PropTypes.number.isRequired,
    portfolio_pk: PropTypes.string.isRequired,
  }).isRequired,
};

export const useAddPosition = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async newPosition => {
      await queryClient.cancelQueries('activeTrades');

      const previousTradePositions = queryClient.getQueryData('activeTrades');

      queryClient.setQueryData('activeTrades', [
        ...(previousTradePositions || []),
        newPosition.data,
      ]);

      return { previousTradePositions };
    },
    onError: (_, __, context) => {
      if (context?.previousTradePositions) {
        queryClient.setQueryData(
          'activeTrades',
          context.previousTradePositions
        );
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('activeTrades');
      queryClient.invalidateQueries('portfolioSummary');
      addNotification({
        type: 'success',
        title: data.detail,
      });
    },
    ...config,
    mutationFn: addPosition,
  });
};
