import { MoonIcon, SunIcon } from '@heroicons/react/outline';
import { useColorModeContext } from 'context/ColorContext';
import React from 'react';

export const ColorSwitch = () => {
  const [darkMode, toggleDarkMode] = useColorModeContext();

  return (
    <div className="flex justify-start items-start px-10">
      <button
        onClick={toggleDarkMode}
        className={`rounded-full flex space-x-2 text-xs items-center py-2 px-3 transition duration-300 ${
          darkMode ? '' : ''
        }`}
      >
        {darkMode ? (
          <SunIcon className="w-4 h-4 text-gray-600" />
        ) : (
          <MoonIcon className="w-4 h-4 text-black" />
        )}
        <p className="text-gray-600">{darkMode ? 'Light' : 'Dark'}</p>
      </button>
    </div>
  );
};
