import { API_URL } from 'config';
import { useNotificationStore } from 'stores/notifications';
import storage from 'utils/storage';
import Axios from 'axios';
import PropTypes from 'prop-types';

function authRequestInterceptor(config) {
  const token = storage.getToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';

  return config;
}

authRequestInterceptor.propTypes = {
  config: PropTypes.any,
};

export const axios = Axios.create({
  baseURL: API_URL,
});

// add multipart/form-data support
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';

// Add request interceptor for logging
axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    const { response } = error;

    if (response && response.status === 401) {
      // Try to refresh the token using the /auth/login/refresh/ endpoint
      const refreshToken = storage.getRefreshToken();

      if (refreshToken) {
        try {
          const refreshResponse = await axios.post('/auth/login/refresh/', {
            refresh: refreshToken,
          });
          const newAccessToken = refreshResponse?.access;
          storage.setToken(newAccessToken); // Update the access token in storage
          useNotificationStore.getState().addNotification({
            type: 'success',
            title: 'Refresh',
            message: 'Application has refreshed',
          });
          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`; // Update the authorization header for the original request
          return axios.request(error.config); // Retry the original request with the new token
        } catch (refreshError) {
          // If token refresh fails, handle the error here
          console.error('Token refresh failed:', refreshError);
        }
      }
    }

    if (response && response.status === 500) {
      // Save error details to local storage
      storage.setErrorResponse(error?.response?.data);
      storage.setErrorMessage(error?.message);
    }
    const message = error.response?.data?.message || "An error has occurred.";
    useNotificationStore.getState().addNotification({
      type: 'error',
      title: message,
      message: response?.data?.detail || 'Server error',
    });
    return Promise.reject(error);
  }
);
