import React from 'react';
import { Instagram, Linkedin, Twitter } from 'react-feather';
import footerImg from 'assets/footer-logo.svg';


export const Footer = () => {
  return (
    <footer className="bg-indigo-500">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 flex justify-center"> {/* Updated */}
        <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
          {/* Follow Us */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Follow Us</h3>
            <ul className="flex flex-row items-center space-x-4">
              <li>
                <a
                  href="https://twitter.com/tickerzone"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:text-white"
                >
                  <Twitter className="h-24" />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/tickerzone"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:text-white"
                >
                  <Instagram className="h-24" />
                </a>
              </li>
              <li>
                <a
                  href="https://linkedin.com/company/tickerzone"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:text-white"
                >
                  <Linkedin className="h-24" />
                </a>
              </li>
            </ul>
            <img className="" src={footerImg} alt="Tickerzone Logo" />
          </div>
        </div>
      </div>
      <div className="pb-8 text-white text-center text-sm">
        Tickerzone Inc. 2024. All rights reserved.
      </div>
    </footer>
  );
};