import clsx from 'clsx';

import React from 'react';
import { useColorModeContext } from 'context/ColorContext';

export const InputFieldV2 = props => {
  const {
    type = 'text',
    value,
    placeholder,
    className,
    maxLength,
    onChange,
    error,
  } = props;

  const darkMode = useColorModeContext();

  return (
    <input
    type={type}
    value={value}
    placeholder={placeholder}
    maxLength={maxLength}
    className={clsx(
        `border border-solid ${error && `border-red-500`} ${
        darkMode[0] ? `bg-dim-100` : `bg-gray-100`
        } border-gray-100 rounded-full w-full outline-none 
        focus:outline-none px-8 py-1.5 dark:bg-gray-900`,
        className
    )}
    onChange={onChange}
    />
  );
};
