import React, { useState, useEffect } from 'react';
import './pricechart.css';

export const PriceChart = ({ entryPrice, targetPrice, currentPrice, quantity }) => {
  // Calculate gains and progress
  const gain = quantity >= 0
    ? ((currentPrice - entryPrice) / entryPrice) * 100
    : ((entryPrice - currentPrice) / entryPrice) * 100;
  
  const progress = gain >= 0
    ? quantity >= 0
      ? ((currentPrice - entryPrice) / (targetPrice - entryPrice)) * 100
      : ((entryPrice - currentPrice) / (entryPrice - targetPrice)) * 100
    : gain;

  // // Professional color palette
  // const colors = {
  //   entry: '#2C3E50',      // Dark blue-gray
  //   target: '#27AE60',     // Professional green
  //   current: '#F1C40F',    // Muted gold
  //   achieved: '#2ECC71',   // Success green
  //   redZone: '#E74C3C40',  // Light red with opacity
  //   blueZone: '#3498DB40', // Light blue with opacity
  //   greenZone: '#2ECC7140' // Light green with opacity
  // };

  // Professional color palette
  const colors = {
    entry: '#2C3E50',      // Dark blue-gray
    target: '#27AE60',     // Professional green
    current: '#F1C40F',    // Muted gold
    achieved: '#2ECC71',   // Success green
    redZone: '#E74C3C30',  // Light red with slightly higher opacity
    blueZone: '#3498DB30', // Light blue with slightly higher opacity
    greenZone: '#2ECC7130', // Light green with slightly higher opacity
    border: '#E2E8F0',     // Light gray border for light mode
    darkBorder: '#4A5568', // Darker border for dark mode
    background: '#F8FAFC', // Light background
    darkBackground: '#1A202C', // Dark mode background
  };

  // Chart dimensions
  const chartWidth = 100;
  const chartHeight = 20;  // Slightly taller for better visibility

  // Calculate positions
  const entryX = quantity >= 0 ? chartWidth / 4 : chartWidth * 3 / 4;
  const targetX = quantity >= 0 ? chartWidth * 3 / 4 : chartWidth / 4;
  
  // Calculate current price position with smoother transitions
  let currentX = progress <= -100 
    ? 0 
    : progress < -10 
    ? entryX * (0.5 + (progress + 10) / 180)
    : progress < 0 
    ? entryX * (1 + 5 * progress / 100)
    : progress <= 100 
    ? entryX + (targetX - entryX) * (progress / 100)
    : progress < 200 
    ? targetX + entryX * (progress - 100) / 100 
    : chartWidth;

  // Adjust for negative quantity
  if (quantity < 0) {
    currentX = progress > 200 
      ? 0
      : progress > 100
      ? targetX - (progress - 100) / 100 * targetX 
      : progress > 0
      ? entryX - (entryX - targetX) * progress / 100
      : progress > -10
      ? entryX - 5 * progress / 100 * targetX
      : progress > -100
      ? entryX + (0.5 + (progress + 10) / 180) * targetX
      : chartWidth;
  }

  // Blinking effect
  const [isBlinking, setIsBlinking] = useState(true);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsBlinking(prev => !prev);
    }, 800); // Slightly slower blink for professionalism

    return () => clearInterval(intervalId);
  }, []);

  // Status checks
  const isTargetAchieved = currentPrice === targetPrice;
  const direction = quantity > 0 ? 1 : -1;
  const redX = quantity > 0 ? currentX : entryX;
  const blueX = quantity > 0 ? entryX : currentX;
  const greenX = quantity > 0 ? targetX : currentX;

  return (
    <div className={`
      relative rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200
      bg-gradient-to-r from-gray-50 to-gray-100 
      dark:from-gray-800 dark:to-gray-750
      border border-gray-200 dark:border-gray-700
      p-1
      ${isTargetAchieved ? 'achieved-target' : ''}
    `}>
      <svg 
        width={chartWidth} 
        height={chartHeight}
        className="overflow-visible"
      >
        {/* Subtle grid lines for better visual reference */}
        <line
          x1="0"
          y1={chartHeight/2}
          x2={chartWidth}
          y2={chartHeight/2}
          stroke={colors.border}
          strokeWidth="0.2"
          strokeDasharray="2,2"
        />
        {/* Background rectangle for better visibility */}
        <rect
          x="0"
          y="0"
          width={chartWidth}
          height={chartHeight}
          fill="transparent"
          stroke={colors.border}
          strokeWidth="0.5"
          rx="2"
        />
        {/* Zone rendering */}
        {progress < 0 && (
          <rect
            x={redX}
            y="0"
            width={direction * (entryX - currentX)}
            height={chartHeight}
            fill={colors.redZone}
            rx="2"
          />
        )}
        {progress >= 0 && progress <= 100 && (
          <rect
            x={blueX}
            y="0"
            width={direction * (currentX - entryX)}
            height={chartHeight}
            fill={colors.blueZone}
            rx="2"
          />
        )}
        {progress > 100 && (
          <rect
            x={greenX}
            y="0"
            width={direction * (currentX - targetX)}
            height={chartHeight}
            fill={colors.greenZone}
            rx="2"
          />
        )}

        {/* Price lines */}
        <line
          x1={entryX}
          y1="0"
          x2={entryX}
          y2={chartHeight}
          stroke={colors.entry}
          strokeWidth="1.5"
        />
        <line
          x1={targetX}
          y1="0"
          x2={targetX}
          y2={chartHeight}
          stroke={isTargetAchieved ? colors.achieved : colors.target}
          strokeWidth="1.5"
        />
        <line
          x1={currentX}
          y1="0"
          x2={currentX}
          y2={chartHeight}
          stroke={colors.current}
          strokeWidth="1.5"
        />

        {/* Target indicator */}
        <polygon
          points={`${targetX - 4},0 ${targetX},${chartHeight - 16} ${targetX + 4},0`}
          fill={isTargetAchieved ? colors.achieved : colors.target}
          className={isBlinking ? 'animate-pulse' : ''}
        />

        {/* Current price indicator */}
        {isBlinking && (
          <circle
            cx={currentX}
            cy={chartHeight / 2}
            r="3"
            fill={colors.current}
            className="animate-pulse"
          />
        )}
      </svg>

      {/* Target achieved indicator */}
      {isTargetAchieved && (
        <div className="absolute -bottom-5 left-0 right-0 text-xs text-center text-green-600">
          Target Met
        </div>
      )}
    </div>
  );
};
