import PropTypes from 'prop-types';
import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useMutation } from 'react-query';

export const scheduleADemo = ({ data }) => {
  return axios.post(`/tapp/notes/demo`, data);
};

scheduleADemo.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    hearAboutUs: PropTypes.string.isRequired,
  }).isRequired,
};

export const useScheduleADemo = ({ config } = {}) => {
  return useMutation({
    onSuccess: data => {
      queryClient.invalidateQueries('demos');
    },
    ...config,
    mutationFn: scheduleADemo,
  });
};
