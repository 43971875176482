import { useDisclosure } from 'hooks/useDisclosure';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Button } from '../elements/button';
import { Drawer } from '../elements/drawer';
import { useColorModeContext } from 'context/ColorContext';



export const FormDrawer = ({
  title,
  children,
  isDone,
  triggerButton,
  submitButton,
  size = 'md',
}) => {
  const { close, open, isOpen } = useDisclosure();

  const darkMode = useColorModeContext();

  React.useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  return (
    <>
      {React.cloneElement(triggerButton, { onClick: open })}
      <Drawer
        isOpen={isOpen}
        onClose={close}
        title={
          <div className={`${
            darkMode[0] ? `text-gray-300` : `text-gray-800`
          } text-m py-3 pt-5 font-bold p-2`}>
            {title}
          </div>
        }
        size={size}
        renderFooter={() => (
          <>
            <Button variant="inverse" size="sm" onClick={close}>
              Cancel
            </Button>
            {submitButton}
          </>
        )}
      >
        {children}
      </Drawer>
    </>
  );
};

FormDrawer.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isDone: PropTypes.bool,
  triggerButton: PropTypes.node.isRequired,
  submitButton: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Drawer.propTypes = {
  title: PropTypes.node.isRequired,
};
