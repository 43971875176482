import React from 'react';
import { Button } from 'components/elements';
import { Form, FormDrawer, InputField, SelectField } from 'components/form';
import PropTypes from 'prop-types';

import { useUpdatePosition } from '../api/updatePosition';
import { InputQuantity } from 'components/form/InputQuantity';
import { useColorModeContext } from 'context/ColorContext';

import './../assets/frameclick.css';

export const UpdatePosition = ({
  avg_price,
  quantity,
  price_target,
  action,
  ticker,
  pk,
}) => {
  const updatePositionMutation = useUpdatePosition();
  const darkMode = useColorModeContext();
  const colorScheme = darkMode[0] ? 'dark' : 'light';

  return (
    <>
      <FormDrawer
        size="sm"
        isDone={updatePositionMutation.isSuccess}
        triggerButton={
          <button className={`trigger-button flex items-center justify-center m-2 mx-4 cursor-pointer ${colorScheme}`}>
            <span className="flex">
              Update <span className="hidden md:flex mx-2">position</span>
            </span>
          </button>
        }
        title={`Record Transaction: Ticker - ${ticker}`}
        submitButton={
          <Button
            form="update-trade-position"
            type="submit"
            size="sm"
            isLoading={updatePositionMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-trade-position"
          onSubmit={async values => {
            values.pk = pk;
            await updatePositionMutation.mutateAsync({
              data: values,
            });
          }}
          options={{
            defaultValues: {
              avg_price,
              quantity,
              price_target,
              action,
            },
          }}
        >
          {({ register, formState }) => (
            <>
              <InputField
                label="Execution Price"
                type="number"
                step={0.001}
                error={formState.errors['avg_price']}
                registration={register('avg_price')}
              />
              <InputQuantity
                label="Quantity"
                type="number"
                step={0.01}
                error={formState.errors['quantity']}
                registration={register('quantity')}
              />
              <SelectField
                label="Action"
                error={formState.errors['action']}
                registration={register('action')}
                options={[
                  { value: 'buy', label: 'Bought' },
                  { value: 'sell', label: 'Sold' },
                ]}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};

UpdatePosition.propTypes = {
  avg_price: PropTypes.number,
  quantity: PropTypes.number,
  price_target: PropTypes.number,
  // action: PropTypes.string.isRequired,
};
