import React from 'react';
import clsx from 'clsx';

export const Select = ({ 
  value, 
  onChange, 
  options = [], 
  className = '', 
  placeholder = 'Select an option',
  disabled = false,
  error,
  ...props 
}) => {
  return (
    <select
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={clsx(
        'block w-full rounded-md shadow-sm',
        'border-gray-300 dark:border-gray-600',
        'bg-white dark:bg-gray-700',
        'text-gray-900 dark:text-gray-100',
        'focus:border-primary-500 focus:ring-primary-500',
        'disabled:bg-gray-100 dark:disabled:bg-gray-800 disabled:cursor-not-allowed',
        error && 'border-red-500',
        className
      )}
      {...props}
    >
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map((option) => (
        <option 
          key={option.value} 
          value={option.value}
          className="bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
        >
          {option.label}
        </option>
      ))}
    </select>
  );
};