import { Button } from 'components/elements';
import { ContentLayout } from 'components/layout';
import React, { useCallback, useState, useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { CreatePortfolio as CreatePortfolioDrawer } from '../components/CreatePortfolio';
import { QuickstartProvider } from 'features/connect/context/index';
import { transmitToken } from 'features/connect/api/transmitToken';
import { useFetchLinkToken } from 'features/connect/api/fetchLinkToken';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/storage';


export const CreatePortfolio = () => {
  const [clicked, setClicked] = useState(false);
  const [initiatePlaid, setInitiatePlaid] = useState(false);
  const { linkToken, fetchLinkToken, isLoading, error } = useFetchLinkToken({
    enabled: initiatePlaid // Only fetch when explicitly enabled
  });
  const navigate = useNavigate();

  // Remove the automatic fetchLinkToken useEffect

  const onSuccess = useCallback(async (public_token, metadata) => {
    let errorMessage = 'An error occurred while linking to your brokerage.';
    try {
      const response = await transmitToken({ public_token });
      if (response.statuscode === 200) {
        storage.setActivePortfolio(response.info.portfolio_id);
        navigate('/app/portfolios/create/success');
      } else {
        if (response.statuscode === 300) {
          errorMessage = 'No valid tickers found. Only publicly traded stocks are currently tracked. Currently working on integrations for ETFs and Mutual funds.';
        }
        else if (response.statuscode === 301) {
          errorMessage = 'Could not link accounts. Please check to confirm link permissions.';
        } else {
          errorMessage = 'No equities found in linked accounts. Note that only publicly listed stocks are tracked.';
        }
        navigate('/app/portfolios/create/failure', { state: { errorMessage } });
      }
    } catch (error) {
      console.error('Error transmitting token:', error);
      navigate('/app/portfolios/create/failure', { state: { errorMessage } });
    }
  }, [navigate]);

  const onExit = useCallback((err, metadata) => {
    console.error('Plaid Link closed by user or error:', err);
    const errorMessage = 'Plaid Link closed suddenly. Try linking another account.';
    navigate('/app/portfolios/create/failure', { state: { errorMessage } });
    setClicked(false);
    setInitiatePlaid(false); // Reset Plaid initiation
  }, [navigate]);

  const config = linkToken ? {
    token: linkToken,
    onSuccess,
    onExit,
  } : {};

  const { open, ready } = usePlaidLink(config);

  const handlePlaidConnect = () => {
    if (!clicked) {
      setInitiatePlaid(true); // Start the token fetch
      setClicked(true);
      // Fetch token and then open Plaid
      fetchLinkToken().then(() => {
        if (ready) {
          open();
        }
      });
    }
  };

  if (error) {
    return (
      <div className="text-center py-8">
        <p className="text-red-500 dark:text-red-400 text-lg">
          Error connecting to Plaid. Please try again later.
        </p>
        <Button
          className="mt-4"
          onClick={() => {
            setClicked(false);
            setInitiatePlaid(false);
          }}
        >
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <ContentLayout page="portfolios" headerContentLeft={<div className="text-3xl font-semibold md:font-bold">Link your Account</div>}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="rounded-md bg-gray-100 dark:bg-dim-200 dark:text-gray-200 py-24 px-12 space-y-4 text-gray-500">
          <div className="font-black">Securely link your account</div>
          <ul>
            <p className="h-32">
              We prioritize your security and privacy. All your account and brokerage information is encrypted, and we never have access to it.
            </p>
          </ul>
          <QuickstartProvider>
            <Button 
              className="w-full py-5" 
              variant="outline" 
              onClick={handlePlaidConnect} 
              disabled={clicked}
              isLoading={isLoading}
            >
              {isLoading ? 'Connecting...' : clicked ? 'Connected' : 'CONNECT'}
            </Button>
          </QuickstartProvider>
        </div>
        <div className="rounded-md bg-gray-100 dark:bg-dim-200 dark:text-gray-200 py-24 px-12 space-y-4 text-gray-500">
          <div className="font-black">Track your holdings manually</div>
          <p className="h-32">
            Add your holdings information by simply adding a ticker, quantity of shares, and average price per share.
          </p>
          <CreatePortfolioDrawer title="CREATE PORTFOLIO" />
        </div>
      </div>
    </ContentLayout>
  );
};
