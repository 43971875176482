import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Input = React.forwardRef(
  ({ 
    type = 'text',
    label,
    value,
    onChange,
    error,
    icon,
    required,
    disabled,
    placeholder,
    className,
    helperText,
    ...props 
  }, ref) => {
    return (
      <div className="w-full">
        {label && (
          <label 
            className={clsx(
              "block text-sm font-medium mb-1",
              disabled ? "text-gray-400 dark:text-gray-500" : "text-gray-700 dark:text-gray-300",
              error && "text-red-600 dark:text-red-400"
            )}
          >
            {label}
            {required && <span className="text-red-500 ml-1">*</span>}
          </label>
        )}
        
        <div className="relative">
          {icon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {icon}
            </div>
          )}
          
          <input
            ref={ref}
            type={type}
            value={value}
            onChange={onChange}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            className={clsx(
              "w-full rounded-lg",
              "px-4 py-2",
              icon && "pl-10",
              "border",
              error
                ? "border-red-500 focus:border-red-500 focus:ring-red-500"
                : "border-gray-300 dark:border-gray-600 focus:border-blue-500 focus:ring-blue-500",
              disabled && "bg-gray-100 dark:bg-gray-800 cursor-not-allowed",
              "dark:bg-gray-700",
              "placeholder-gray-400 dark:placeholder-gray-500",
              "text-gray-900 dark:text-gray-100",
              "focus:outline-none focus:ring-2 focus:ring-opacity-50",
              "transition duration-200",
              className
            )}
            {...props}
          />
        </div>

        {/* Error or Helper Text */}
        {(error || helperText) && (
          <p 
            className={clsx(
              "mt-1 text-sm",
              error 
                ? "text-red-600 dark:text-red-400" 
                : "text-gray-500 dark:text-gray-400"
            )}
          >
            {error || helperText}
          </p>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  icon: PropTypes.node,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
};

// export default Input;