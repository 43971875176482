import React from 'react';
import { HomeNavbar } from './HomeNavbar';
import PropTypes from 'prop-types';

export const Header = ({ height, children }) => {
  return (
    <div className="relative">
      <div className={`bg-[#0C0C0E] opacity-[.84] h-${height}`}>
        <HomeNavbar />
        <div className="">{children}</div>
      </div>
    </div>
  );
};

Header.propTypes = {
  //   height: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
