import React, { useState } from 'react';
import { AppNavBar } from './components/AppNavBar';
import { SideBar } from './components/SideBar';
import {
  FiActivity,
  FiAirplay,
  FiInfo,
  FiList,
  FiRadio,
  FiShoppingBag,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { MobileViewLayout } from './MobileViewLayout';
import { useNotifications } from './api/getNotifications';
import { SidebarFooter } from './components/SidebarFooter';
import clsx from 'clsx';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const AppLayout = ({ children, page }) => {
  const [deskSideBarOpen, setDeskSideBarOpen] = useState(true); //for desktop
  const [sidebarOpen, setSidebarOpen] = useState(false); //for mobile
  const notificationsQuery = useNotifications();

  if (notificationsQuery.isLoading) {
    return <></>;
  }

  if (!notificationsQuery.data) {
    return null;
  }

  return (
    <div className="h-full dark:bg-dim-300 dark:text-white">
      {/* Desktop layout */}
      <div className="hidden lg:flex relative">
        {/* Sidebar container - Updated styles */}
        <div className={clsx(
          'h-screen sticky top-0',
          'transition-all duration-300 ease-in-out',
          deskSideBarOpen ? 'w-64' : 'w-20'
        )}>
          <SideBar 
            page={page} 
            sidebarOpen={deskSideBarOpen} 
            setSidebarOpen={setDeskSideBarOpen}
          />
        </div>
        <div className="w-full h-screen overflow-y-auto">
          <AppNavBar
            sidebarOpen={deskSideBarOpen}
            setSidebarOpen={setDeskSideBarOpen}
          />

          <div className="py-4 mx-8 md:ml-24 md:mr-24 mx-auto max-w-screen-2xl 2xl:p-10">
            {children}
          </div>
        </div>
      </div>

      {/* Mobile & Tablet layout */}
      <div className="block lg:hidden h-full">
        <MobileViewLayout
          open={sidebarOpen}
          onclose={() => setSidebarOpen(false)}
        >
          <div className="flex flex-col h-full justify-between">
            <div className="py-8 space-y-4 mx-10">
              <Link
                to="/app/"
                className={classNames(
                  page === 'overview'
                    ? 'bg-flamingo-500 text-white'
                    : 'dark:text-gray-300',
                  `flex items-center md:text-xl space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
                )}
              >
                <FiAirplay />
                <p>Overview</p>
              </Link>
              <Link
                to="/app/portfolios"
                className={classNames(
                  page === 'portfolios'
                    ? 'bg-flamingo-500 text-white'
                    : 'dark:text-gray-300',
                  `flex items-center md:text-xl space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
                )}
              >
                <FiActivity />
                <p>Holdings</p>
              </Link>
              <Link
                to="/app/watchlist"
                className={classNames(
                  page === 'watchlist'
                    ? 'bg-flamingo-500 text-white'
                    : 'dark:text-gray-300',
                  `flex items-center md:text-xl space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
                )}
              >
                <FiList />
                <p>Watchlist</p>
              </Link>
              <Link
                to="/app/trades/logs"
                className={classNames(
                  page === 'trades/logs'
                    ? 'bg-flamingo-500 text-white'
                    : 'dark:text-gray-300',
                  `flex items-center md:text-xl space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
                )}
              >
                <FiRadio />
                <p>Trade logs</p>
              </Link>
              <Link
                to="/app/notifications"
                className={classNames(
                  page === 'notifications'
                    ? 'bg-flamingo-500 text-white'
                    : 'dark:text-gray-300',
                  `flex items-center md:text-xl space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
                )}
              >
                <FiShoppingBag />
                <p className="flex flex-row items-center space-x-2">
                  <span>Notifications</span>
                </p>
              </Link>
              <Link
                to="/app/support"
                className={classNames(
                  page === 'support'
                    ? 'bg-flamingo-500 text-white'
                    : 'dark:text-gray-300',
                  `flex items-center md:text-xl space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
                )}
              >
                <FiInfo />
                <p>Support</p>
              </Link>
            </div>
            <SidebarFooter />
          </div>
        </MobileViewLayout>
        <div className="w-full dark:bg-dim-300 h-full flex flex-col">
          <div>
            <AppNavBar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          </div>
          <div className="py-4 mx-8 min-h-screen">{children}</div>
        </div>
      </div>
    </div>
  );
};
