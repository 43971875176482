import { MainLayout } from 'components/layout';
import React, { useEffect } from 'react';
import { Header } from '../components/Header';
import { Button } from 'components/elements';
import { useNavigate } from 'react-router-dom';
import { ScheduleADemo } from '../components/ScheduleADemo';

export const GetStarted = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <MainLayout headerContent={<Header start="Get Star" tail="ted" />}>
      <div className="dark:bg-dim-300 dark:text-white">
        <div className="mx-8 md:mx-28 py-20">
          <div className="text-4xl font-black">
            Featur{''}
            <u className="underline decoration-4 underline-offset-[15px] decoration-[#FE5F00]">
              es.
            </u>
            <div className="w-full grid md:grid-cols-2 grid-col-1 gap-6 mt-16 mb-6">
              <div className="w-full rounded-lg p-6 bg-[#EFEFFD] dark:bg-[#2C2C35]">
                <h5
                  className="mb-2 text-xl font-semibold leading-tight text-neutral-800
                dark:text-white text-[#000000]"
                >
                  Schedule a demo
                </h5>
                <p
                  className="mb-4 text-base font-medium text-neutral-600
                dark:text-white text-[#000000]"
                >
                  Streamline your investments with a Calendly consultation.
                  Optimize your portfolio, track performance, and make informed
                  decisions effortlessly. Book now!
                </p>
                <div className="flex flex-col md:flex-row space-x-2 items-center">
                  <ScheduleADemo />
                  <Button size="sm" className="whitespace-nowrap mb-2">
                    Subscribe Now
                  </Button>
                </div>
              </div>
              <div className="rounded-lg p-6 bg-[#F3D9DA] dark:bg-[#302828] dark:text-white">
                <h5
                  className="mb-2 text-xl font-semibold leading-tight text-neutral-800 
                dark:text-white text-[#000000]"
                >
                  Sign Up
                </h5>
                <p
                  className="mb-4 text-base font-medium text-neutral-600 dark:text-white
                 text-[#000000]"
                >
                  Embark on your path to financial success with our Investment
                  Portfolio Management platform. Experience a free 14-day trial,
                  effortlessly tracking investments and making informed
                  decisions. When ready, choose a payment plan to continue
                </p>
                <Button
                  onClick={() => navigate('/auth/create-new-account')}
                  size="sm"
                  className="whitespace-nowrap"
                >
                  Continue Signup
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-indigo-500 dark:bg-dim-300">
          <div className="mx-8 md:mx-28 py-20">
            <h4 className="text-4xl text-[#FFFFFF]">Why Tickerzone.</h4>
            <div className="w-full mt-12 mb-0">
              <p className="mb-4 text-base font-medium text-neutral-600 text-white mb-3">
                At TickerZone, our unwavering mission is to transform investment
                tracking and facilitate intelligent portfolio management. We
                have meticulously crafted a forthcoming platform that empowers
                users to make informed decisions and take prompt actions,
                liberating them from the need to be constantly tethered to
                multiple news channels. With TickerZone, you can fearlessly
                navigate the investment landscape with seamless ease. Check out
                our blog and learn more about our founding story.
              </p>
              <Button onClick={() => navigate('/blog')}>our blog</Button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
