import React from 'react';
import { Link } from 'react-router-dom';

export const Hero = () => {
  return (
    <div className="text-center py-36 space-y-10 px-8">
      <div className="space-y-2">
        <h1 className="text-white text-5l md:text-[5rem]">
          Smart Investing
          <br />
          <span className="text-orange-500 text-[2.0rem] md:text-[5rem] font-bold">
            Starts Here  
            {/* <span className="underline decoration-4 underline-offset-[15px] decoration-[#FE5F00]">
              ere
            </span> */}
          </span>
        </h1>
      </div>
      <div className="w-full md:w-1/2 mx-auto">
        <p className="text-white font-semibold md:font-bold text-sm md:text-xl">
        Tickerzone monitors your investments for you, and delivers insights tailored to 
        your portfolio and risk tolerance, helping you make decisions aligned with your 
        investment strategy.
        {/* Introducing your <span className="text-orange-500 font-bold">AI advisor</span>: Constantly monitoring 
        your stock holdings and delivering personalized insights 
        tailored to your unique risk tolerance, 
        ensuring informed and strategic decisions. */}
        </p>
      </div>
      <div>
        <Link
          to="/auth/create-new-account/"
          className="text-white uppercase bg-flamingo-500 px-3 py-2 rounded-md text-xs font-medium"
        >
          Get started
        </Link>
      </div>
    </div>
  );
};
