import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Holdings } from './Holdings';
import { CreatePortfolio } from './CreatePortfolio';
import { CreatePortfolioSuccess } from './CreatePortfolioSuccess';
import { CreatePortfolioFailure } from './createPortfolioFailure';
import { OAuthReturn } from './oauth-return';

export const PortfoliosRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Holdings />} />
      <Route path="create" element={<CreatePortfolio />} />
      <Route path="create/success" element={<CreatePortfolioSuccess />} />
      <Route path="create/failure" element={<CreatePortfolioFailure />} />
      <Route path="link/return" element={<OAuthReturn />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
