import { Button, FullScreenLoader } from 'components/elements';
import { ContentLayout } from 'components/layout';
import React, { useEffect, useState, useRef } from 'react';
import { ChevronDown } from 'react-feather';
import { StockHoldings } from '../components/StockHoldings';
import { OptionHoldings } from '../components/OptionHoldings';
import { usePortfolios } from '../api/getPortfolios';
import { useActiveTrades } from '../api/getActiveTrades';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/storage';
import { AddPosition } from '../components/AddPosition';
import { AddOptionPosition } from '../components/AddOptionPosition';

export const Holdings = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showTickerTypeDropdown, setShowTickerTypeDropdown] = useState(false);
  const [selectedTickerType, setSelectedTickerType] = useState('stock');
  const [selectedPortfolio, setSelectedPortfolio] = useState(
    storage.getActivePortfolio()
  );
  const [selectedPortfolioName, setSelectedPortfolioName] = useState();
  // Add refs for the dropdown containers
  const tickerTypeRef = useRef(null);
  const portfolioRef = useRef(null);

  const portfoliosQuery = usePortfolios({ 
    ticker_type: selectedTickerType,
    config: {
      // Any additional config options
    }
  }); 
  const activeTradesQuery = useActiveTrades(
    selectedPortfolio ? { selectedPortfolio, tickerType: selectedTickerType } : { enabled: false }
  );
  const navigate = useNavigate();


  // Initial load effect
  useEffect(() => {
    if (portfoliosQuery.data && portfoliosQuery.data.length > 0) {
      const savedPortfolioId = storage.getActivePortfolio();
      if (savedPortfolioId) {
        const selected = portfoliosQuery.data.find(
          p => p.portfolio_id === savedPortfolioId
        );
        if (selected) {
          setSelectedPortfolio(selected.portfolio_id);
          setSelectedPortfolioName(selected.portfolio_name);
          setSelectedTickerType(selected.ticker_type || 'stock');
        } else {
          // If saved portfolio is not found, reset storage and use first portfolio
          storage.clearActivePortfolio();
          const defaultPortfolio = portfoliosQuery.data[0];
          setSelectedPortfolio(defaultPortfolio.portfolio_id);
          setSelectedPortfolioName(defaultPortfolio.portfolio_name);
          setSelectedTickerType(defaultPortfolio.ticker_type || 'stock');
        }
      } else {
        // No saved portfolio, use first available
        const defaultPortfolio = portfoliosQuery.data[0];
        setSelectedPortfolio(defaultPortfolio.portfolio_id);
        setSelectedPortfolioName(defaultPortfolio.portfolio_name);
        setSelectedTickerType(defaultPortfolio.ticker_type || 'stock');
      }
    }
  }, [portfoliosQuery.data]);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tickerTypeRef.current && 
          !tickerTypeRef.current.contains(event.target) && 
          !event.target.closest('[data-dropdown-item="ticker-type"]')) {
        setShowTickerTypeDropdown(false);
      }
      if (portfolioRef.current && 
          !portfolioRef.current.contains(event.target) && 
          !event.target.closest('[data-dropdown-item="portfolio"]')) {
        setShowDropdown(false);
      }
    };

    if (showTickerTypeDropdown || showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTickerTypeDropdown, showDropdown]);

  const handleTickerTypeToggle = () => {
    setShowTickerTypeDropdown(!showTickerTypeDropdown);
    setShowDropdown(false);
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
    setShowTickerTypeDropdown(false);
  };

  const handlePortfolioSelect = option => {
    setSelectedPortfolio(option.portfolio_id);
    setSelectedPortfolioName(option.portfolio_name);
    setShowDropdown(false);
    storage.setActivePortfolio(option.portfolio_id);
  };

  const hasPortfoliosForTickerType = () => {
    return portfoliosQuery.data?.some(p => p.ticker_type === selectedTickerType);
  };

  const handleTickerTypeSelect = (type) => {
    setSelectedTickerType(type);
    setShowTickerTypeDropdown(false);
    
    // Check if portfolios exist for the selected type
    const portfoliosOfType = portfoliosQuery.data?.filter(p => p.ticker_type === type);
    if (portfoliosOfType?.length > 0) {
      const defaultPortfolio = portfoliosOfType[0];
      setSelectedPortfolio(defaultPortfolio.portfolio_id);
      setSelectedPortfolioName(defaultPortfolio.portfolio_name);
      storage.setActivePortfolio(defaultPortfolio.portfolio_id);
    } else {
      setSelectedPortfolio(null);
      setSelectedPortfolioName(null);
      storage.setActivePortfolio(null);
    }
  };

  if (portfoliosQuery.isLoading || activeTradesQuery.isLoading) {
    return <FullScreenLoader />;
  }

  if (!portfoliosQuery.data || !activeTradesQuery.data) {
    return null;
  }

  return (
    <ContentLayout
      headerContentLeft={
        <div className="text-sm md:text-md flex items-center space-x-4">
          <span 
            className="cursor-pointer relative" 
            ref={tickerTypeRef}
            onClick={handleTickerTypeToggle}
          >
            <span className="text-flamingo-500 dark:text-flamingo-500">
              {selectedTickerType === 'stock' ? 'Stocks' : 'Options'}
            </span>
            <ChevronDown className="inline-block w-4 h-4 ml-1" />
            {showTickerTypeDropdown && (
              <div className="origin-top-left absolute z-20 mt-2 w-32 rounded-md shadow-lg bg-white dark:bg-dim-400 ring-1 ring-black ring-opacity-5">
                <div className="py-1" role="menu" aria-orientation="vertical">
                  <div
                    data-dropdown-item="ticker-type"
                    className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-dim-300"
                    onClick={() => handleTickerTypeSelect('stock')}
                  >
                    Stocks
                  </div>
                  <div
                    data-dropdown-item="ticker-type"
                    className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-dim-300"
                    onClick={() => handleTickerTypeSelect('option')}
                  >
                    Options
                  </div>
                </div>
              </div>
            )}
          </span>
          <span 
            className={`cursor-pointer relative ${!hasPortfoliosForTickerType() ? 'opacity-50 pointer-events-none' : ''}`}
            ref={portfolioRef}
            onClick={handleDropdownToggle}
          >
            {selectedPortfolioName || 'Select Portfolio'}
            <ChevronDown className="inline-block w-4 h-4 ml-1" />
            {showDropdown && hasPortfoliosForTickerType() && (
              <div className="origin-top-left absolute z-20 mt-2 w-64 rounded-md shadow-lg bg-white dark:bg-dim-400 ring-1 ring-black ring-opacity-5">
                <div className="py-1" role="menu" aria-orientation="vertical">
                  {portfoliosQuery.data
                    .filter(option => option.ticker_type === selectedTickerType)
                    .map((option, index) => (
                      <div
                        key={index}
                        data-dropdown-item="portfolio"
                        className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-dim-300"
                        onClick={() => handlePortfolioSelect(option)}
                      >
                        {option.portfolio_name}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </span>
        </div>
      }
      headerContentRight={
        <>
          <div className="hidden md:flex flex-row space-x-2 items-center">
            {selectedTickerType === 'stock' ? (
              <AddPosition
                triggerButton={
                  <Button 
                    size="sm" 
                    variant="outline" 
                    className="whitespace-nowrap"
                    disabled={!selectedPortfolio}
                  >
                    Add Stock Trade
                  </Button>
                }
              />
            ) : (
              <AddOptionPosition
                triggerButton={
                  <Button 
                    size="sm" 
                    variant="outline" 
                    className="whitespace-nowrap"
                    disabled={!selectedPortfolio}
                  >
                    Add Option Trade
                  </Button>
                }
              />
            )}
          </div>
          <div className="md:hidden space-y-2">
            {selectedTickerType === 'stock' ? (
              <AddPosition
                triggerButton={
                  <Button 
                  size="sm" 
                  variant="outline" 
                  className="w-full"
                  disabled={!selectedPortfolio}
                >
                    Add Stock Trade
                </Button>
              }
              />
            ) : (
              <AddOptionPosition
                triggerButton={
                  <Button 
                    size="sm" 
                    variant="outline" 
                    className="w-full"
                    disabled={!selectedPortfolio}
                  >
                    Add Option Trade
                  </Button>
                }
              />
            )}
            <Button
              size="sm"
              className="w-full"
              onClick={() => navigate('/app/portfolios/create')}
            >
              Create Portfolio
            </Button>
          </div>
        </>
      }
    >
      <div className="w-80 md:w-[47rem] lg:w-full overflow-x-auto lg:overflow-visible">
        {portfoliosQuery.isLoading ? (
          <FullScreenLoader />
        ) : portfoliosQuery.error ? (
          <div className="text-center py-8">
            <p className="text-red-500 dark:text-red-400 text-lg">
              Error loading portfolios. Please try again later.
            </p>
          </div>
        ) : !hasPortfoliosForTickerType() ? (
          <div className="text-center py-8">
            <p className="text-gray-600 dark:text-gray-300 text-lg">
              No portfolios found for {selectedTickerType === 'stock' ? 'Stocks' : 'Options'}
            </p>
            <p className="text-gray-500 dark:text-gray-400 mt-2">
              Create a new portfolio to get started
            </p>
          </div>
        ) : !selectedPortfolio ? (
          <div className="text-center py-8">
            <p className="text-gray-600 dark:text-gray-300 text-lg">
              Select a portfolio to view holdings
            </p>
            <p className="text-gray-500 dark:text-gray-400 mt-2">
              Choose from the dropdown above or create a new portfolio
            </p>
            <div className="mt-4 space-x-4">
              <Button
                size="sm"
                variant="outline"
                onClick={handleDropdownToggle}
              >
                Select Portfolio
              </Button>
              <Button
                size="sm"
                onClick={() => navigate('/app/portfolios/create')}
              >
                Create Portfolio
              </Button>
            </div>
          </div>
        ) : activeTradesQuery.isLoading ? (
          <FullScreenLoader />
        ) : activeTradesQuery.error ? (
          <div className="text-center py-8">
            <p className="text-red-500 dark:text-red-400 text-lg">
              Error loading holdings. Please try again later.
            </p>
            <Button
              size="sm"
              variant="outline"
              className="mt-4"
              onClick={() => activeTradesQuery.refetch()}
            >
              Try Again
            </Button>
          </div>
        ) : (
          selectedTickerType === 'stock' ? (
            <StockHoldings data={activeTradesQuery.data} />
          ) : (
            <OptionHoldings data={activeTradesQuery.data} />
          )
        )}
    </div>
    </ContentLayout>
  );
};
