import React, { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';

export const OAuthReturn = () => {
  const navigate = useNavigate();
  const { open, ready } = usePlaidLink({
    receivedRedirectUri: window.location.href,
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  useEffect(() => {
    if (!ready) {
      navigate('/app/portfolios/create/failure', { state: { errorMessage: 'OAuth flow failed. Please try again.' } });
    }
  }, [ready, navigate]);

  return <p>Loading...</p>;
};