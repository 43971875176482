import React from 'react';
import { Link } from 'react-router-dom';
import { Heading } from 'components/layout/components/Heading';
import novice_investor from 'assets/tickerzone_streamlines.png';
import risky_exposure from 'assets/risk_exposure.jpeg';
import target_setting from 'assets/target_setting.jpeg';

export const Portfolio = () => {
  return (
    <div className="w-5/6 mx-auto my-8 md:mb-14 -mt-0 pb-8 pt-20">
      <Heading color="black">Track, analyze, and grow your investments.</Heading>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-12 my-8">
        <div className="text-center">
          <img
            src={novice_investor}
            alt="charts"
            className="rounded-md shadow-lg my-2 h-40 w-auto mx-auto"
          />
          <h3>Streamline decision-making</h3>
          <p className="px-4">
            Tickerzone helps you make informed decisions and by tracking assets 
            against your performance expectations.
          </p>
        </div>
        <div className="text-center">
          <img
            src={risky_exposure}
            alt="board"
            className="rounded-md shadow-lg my-2 h-40 w-auto mx-auto"
          />
          <h3>Stay Informed, Not Obsessed.</h3>
          <p className="px-4">
            Strict monitoring and alerts allows you to course-correct when 
            needed and gives you the freedom to focus on what matters the most
          </p>
        </div>
        <div className="text-center">
          <img
            src={target_setting}
            alt="real estate"
            className="rounded-md shadow-lg my-2 h-40 w-auto mx-auto"
          />
          <h3>Reach your goals</h3>
          <p className="px-4">
            Set clear objectives, identify areas for improvement, and optimize your portfolio.
            TickerZone provides seamless target-setting tools, ensuring that each investment has a predetermined outcome in sight.
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Link
          to="/auth/create-new-account/"
          className="text-white uppercase bg-flamingo-500 px-3 py-2 rounded-md text-xs font-medium"
        >
          Get started
        </Link>
      </div>
    </div>
  );
};
