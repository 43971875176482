import { axios } from 'lib/axios';


/**
 * Fetch the list of supported brokerages from the backend.
 * @returns {Promise<string[]>} A promise that resolves to an array of brokerage names.
 */
export const fetchSupportedBrokerages = async () => {
    try {
        const response = await axios.get('/tapp/account/get_brokerages');;
        if (!response.ok) {
            throw new Error('Failed to fetch supported brokerages');
        };
        return response.supported_brokerages; // Assuming the API returns an object with a 'brokerages' array
    } catch (error) {
      console.error('Error fetching supported brokerages:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  };
