import React from 'react';
import { AuthLayout } from '../components/AuthLayout';

export const ResetPasswordInfo = () => {
  return (
    <AuthLayout option="reset-password-info">
      <div className="text-center my-32">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">
          Success! 
        </h1>
        <p className="text-lg md:text-xl mb-8">
          Please check your email for a password-reset link.
        </p>
      </div>
    </AuthLayout>
  );
};