import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const updateTrade = ({ data }) => {
  return axios.put(`/tapp/trades/update_fields`, data);
};

export const useUpdateTrade = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async updatingTrade => {
      await queryClient.cancelQueries(['activeTrade', updatingTrade?.tradeId]);

      const previousTrades =
        queryClient.getQueryData[('activeTrade', updatingTrade?.tradeId)];

      queryClient.setQueryData(['activeTrade', updatingTrade?.tradeId], {
        ...previousTrades,
        ...updatingTrade.data,
        id: updatingTrade.tradeId,
      });

      return { previousTrades };
    },
    onError: (_, __, context) => {
      if (context?.previousTrades) {
        queryClient.setQueryData(
          ['activeTrade', context.previousTrades.id],
          context.previousTrades
        );
      }
    },
    onSuccess: data => {
      queryClient.refetchQueries(['activeTrades']);

      addNotification({
        type: 'success',
        title: data.detail,
      });
    },
    ...config,
    mutationFn: updateTrade,
  });
};
